import React from 'react'
import Certification from './Certification'
import Grid from '@material-ui/core/Grid';

import certArchDataDesigner from '../../../assets/images/certifications/cert-data-arch-designer.png'
import certAdmin from '../../../assets/images/certifications/cert-admin.png'
import certJsDev from '../../../assets/images/certifications/cert-js-dev-1.png'
import certAppBuilder from '../../../assets/images/certifications/cert-platform-app-builder.png'
import certDev1 from '../../../assets/images/certifications/cert-platform-dev-1.png'
import certDev2 from '../../../assets/images/certifications/cert-platform-dev-2.png'

const Certifications = () => (
  <section id="two">
    <h2>Certifications</h2>

    <Grid container>
      <Certification
        source={certArchDataDesigner}
        description="Salesforce Certified Data Architecture and Management Designer"
      />
      <Certification
        source={certJsDev}
        description="Salesforce certified JavaScript Developer I"
      />
      <Certification
        source={certDev1}
        description="Salesforce Certified Platform Developer I"
      />
      <Certification
        source={certDev2}
        description="Salesforce Certified Platform Developer II"
      />
      <Certification
        source={certAdmin}
        description="Salesforce Certified Administrator"
      />
      <Certification
        source={certAppBuilder}
        description="Salesforce Certified Platform App Builder"
      />
    </Grid>
  </section>
)

export default Certifications
