import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'

import AboutMe from '../components/AboutMe'
import Certifications from '../components/Certifications'
import GetInTouch from '../components/GetInTouch'

const HomeIndex = () => {
  const siteTitle = 'Michal Verner - Portfolio'
  const siteDescription = `Michal Verner portfolio is about Michal's career as a Salesforce developer. It has about me section, shows all Michal's certifications and provides basic contact information.`

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
        <meta name="google-site-verification" content="pVS48Qkzu7NmS84DXQ5FckbDBfgxeayZXPqsRc0MWlo" />
        <meta name="seznam-wmt" content="93TLh7oQEfb2lDrsroUgTjU5mcfHYoIe" />
      </Helmet>

      <div id="main">
        <AboutMe />
        <Certifications />
        <GetInTouch />
      </div>
    </Layout>
  )
}

export default HomeIndex
