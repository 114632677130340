import React from 'react'

const AboutMe = () => (
  <section id="one">
    <header className="major">
      <h2>About Me</h2>
    </header>
    <p>
      I’m a software developer building projects and new stuff for almost a
      decade. I started as a part time test engineer on the university, then I
      moved to Java development where I worked on a really cool project with great
      people. In that time I had an opportunity to learn something new and try
      some of the emerging platforms on the market. That’s how I started with
      Salesforce. I liked the idea to have platform with some basic business
      capability with the ability to extend it as needed. Apex is quite similar
      to Java so it enabled me to learn how to develop, build and deploy to
      platform quickly. Since then Salesforce world has changed a lot creating
      its own economy and impacted loads of people and companies. It's an
      exciting world.
    </p>
    <p>
      During my Salesforce career I was able to explore different parts of
      Salesforce platform, build lots of different types of solutions, see how
      Salesforce is used in different industries and hit almost every limit.
    </p>
    <p>
      I've worked for different companies and clients. I've spent quite a bit of
      my career life working in London, which was a great experience. I had the
      opportunity to meet really interesting people and develop Salesforce in a
      startup environment.
    </p>
  </section>
)

export default AboutMe
