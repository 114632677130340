import React from 'react'

const GetInTouch = () => (
  <section id="three">
    <h2>Get In Touch</h2>
    <p>
      Please <a href="mailto:michal.verner.mv@gmail.com">let me know</a> if you
      are interested in cooperation and we can discuss possibilities how I can help.
    </p>
    <ul className="labeled-icons">
      <li>
        <h3 className="icon fa-envelope-o">
          <span className="label">Email</span>
        </h3>
        <a href="mailto:michal.verner.mv@gmail.com">
          michal.verner.mv@gmail.com
        </a>
      </li>
    </ul>
  </section>
)

export default GetInTouch