import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid';

const Certification = ({ source, description }) => (
  <Grid item xs={6} sm={4}>
    <img className="image fit" src={source} alt={description}/>
  </Grid>
)

Certification.propTypes = {
  source: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default Certification
